import { LoadingOutlined } from '@ant-design/icons';
import { queryClient } from 'App/queryClient';
import { Spin } from 'antd';
import type { ICompartilhar } from 'components-old/merc/FormCompartilhar';
import { notifyError } from 'features/erro/util';
import type { ApiFormaPagamento } from 'features/forma-pagamento/api/apiFormaPagamentoConsultar';
import { getRegrasProduto } from 'features/produto/util';
import {
    apiVendaFormaPagamentoDelete,
    apiVendaFormaPagamentoGet,
    apiVendaFormaPagamentoPost,
    apiVendaFormaPagamentoPut,
} from 'features/venda-forma-pagamento/api';
import {
    apiVendaItemDelete,
    apiVendaItemGet,
    apiVendaItemPost,
    apiVendaItemPut,
} from 'features/venda-item/api';
import { type ApiVendaGerarNfce, apiVendaGet, apiVendaPost } from 'features/venda/api';
import type { ApiVenda } from 'features/venda/types';
import { nanoid } from 'nanoid';
import type { ReactNode } from 'react';
import { numberToCurrency } from 'std/format';
import { roundAbnt } from 'std/math';
import { type AsyncResult, err, ok } from 'std/rusty/result';
import type { TipoDesconto } from 'std/types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { PdvVenda, PdvVendaFormaPagamento, PdvVendaItem } from './types/PdvVenda';
import {
    PRECISION_PDV,
    calcularTotalProduto,
    formaPagamentoToVendaFormaPagamento,
    getIsCartaoOrPix,
    verificarFormaPagamentoCartaoPix,
} from './util';

const pdvDisponivel = 'PDV DISPONÍVEL';

type PdvStoreState = {
    venda: PdvVenda | null;
    vendaFinalizada: boolean;
    favoriteProducts: boolean;
    fastMode: boolean;
    serieNfce: number | null;
    title: ReactNode;
    focarInputProduto: boolean;
    gerouNfce: boolean;

    showModalCancelar: boolean;
    showDrawerCliente: boolean;
    showDrawerFormasPagamento: boolean;
    showDrawerEmitir: boolean;

    showDrawerProduto: boolean;
    drawerProdutoItem: PdvVendaItem | null;

    showDrawerCompartilhar: boolean;
    drawerCompartilharDados: ICompartilhar | null;

    emissaoNfce: ApiVendaGerarNfce | null;

    impressaoSolicitada: boolean;
    impressaoSolicitadaNfce: boolean;

    valoresRecebidos: {
        valorRecebido: number;
        troco: number;
    } | null;
};

type PdvStoreActions = {
    resetToInitialState: () => void;
    updatePdvTitle: (title: string, loading: boolean) => void;

    // Venda
    newVenda: () => Promise<number | null>;
    loadVenda: (venda: ApiVenda) => Promise<void>;
    aplicarDesconto: (tipoDesconto: TipoDesconto, desconto: number) => void;
    updateTotalVenda: () => void;
    finalizarVenda: (gerouNfce: boolean) => void;

    // Itens
    insertItem: (item: PdvVendaItem) => Promise<void>;
    updateItemLocal: (key: string, item: Partial<PdvVendaItem>) => void;
    updateItem: (key: string, item: Partial<PdvVendaItem>) => Promise<void>;
    deleteItemLocal: (key: string) => void;
    deleteItem: (key: string) => Promise<void>;

    // Formas de pagamento
    updateVendaFormasPagamento: () => AsyncResult<null, string>;

    insertFormaPagamento: (
        valor: number,
        formaPamento: ApiFormaPagamento,
    ) => AsyncResult<string, string>;

    updateFormaPagamento: (
        key: string,
        forma: Partial<PdvVendaFormaPagamento>,
    ) => AsyncResult<null, string>;

    deleteFormaPagamento: (key: string) => AsyncResult<null, string>;
};

type PdvStore = PdvStoreState & PdvStoreActions;

const initialState: PdvStoreState = {
    venda: null,
    vendaFinalizada: false,
    favoriteProducts: false,
    fastMode: false,
    serieNfce: null,
    title: pdvDisponivel,
    focarInputProduto: true,
    gerouNfce: false,

    showModalCancelar: false,
    showDrawerCliente: false,
    showDrawerFormasPagamento: false,
    showDrawerEmitir: false,

    showDrawerProduto: false,
    drawerProdutoItem: null,

    showDrawerCompartilhar: false,
    drawerCompartilharDados: null,

    emissaoNfce: null,

    impressaoSolicitada: false,
    impressaoSolicitadaNfce: false,

    valoresRecebidos: null,
};

export const usePdvStore = create<PdvStore>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,

                resetToInitialState() {
                    const { favoriteProducts, fastMode } = get();
                    set({ ...initialState, favoriteProducts, fastMode });
                },

                updatePdvTitle(title: string, loading: boolean) {
                    set({
                        title: loading ? (
                            <>
                                <Spin
                                    indicator={<LoadingOutlined spin={true} />}
                                    className='mr-4 text-white'
                                    size='large'
                                />
                                {title}
                            </>
                        ) : (
                            title
                        ),
                    });
                },

                async newVenda() {
                    try {
                        get().updatePdvTitle('CRIANDO VENDA', true);

                        const response = await apiVendaPost({
                            ven_tipo: 'pedido',
                        });

                        const vendas = await apiVendaGet({
                            idpk: response.ven_idpk,
                        });

                        const venda = vendas[0] as PdvVenda;
                        set({ venda, title: `VENDA ${venda.ven_numero}` });
                        queryClient.refetchQueries({ queryKey: ['apiVendaGet', 'infinite'] });

                        return venda.ven_idpk || null;
                    } catch {
                        get().updatePdvTitle(pdvDisponivel, false);
                        return null;
                    }
                },

                async loadVenda(venda: ApiVenda) {
                    try {
                        get().updatePdvTitle('CARREGANDO VENDA', true);

                        const itensApi = await apiVendaItemGet({
                            params: {
                                venda_idpk: venda.ven_idpk,
                            },
                        });

                        const itens: PdvVendaItem[] = itensApi.map((item) => ({
                            key: nanoid(),
                            regrasProduto: getRegrasProduto(item.produto),
                            ...item,
                        }));

                        const formas: PdvVendaFormaPagamento[] = (
                            venda.formas_de_pagamento || []
                        ).map((fp) => ({
                            ...fp,
                            key: fp.vfp_idpk.toString(),
                        }));

                        set({
                            title: `VENDA ${venda.ven_numero}`,
                            venda: {
                                ...venda,
                                itens,
                                formas_de_pagamento: formas,
                            },
                        });
                    } catch {
                        get().updatePdvTitle(pdvDisponivel, false);
                    }
                },

                aplicarDesconto(tipoDesconto, desconto): void {
                    const venda = get().venda;
                    if (!venda) {
                        return;
                    }

                    const ven_produtos_valor = venda.ven_produtos_valor || 0;

                    const descontoTotal =
                        tipoDesconto === '$' ? desconto : (desconto / 100) * ven_produtos_valor;

                    const itens = get().venda?.itens || [];
                    if (itens.length === 0) {
                        return;
                    }

                    const descontoPorItem = roundAbnt(descontoTotal / itens.length);
                    const descontoReal = descontoPorItem * itens.length;
                    const diff = descontoTotal - descontoReal;

                    const newItens: PdvVendaItem[] = itens.map((i, idx) => {
                        const descontoItem = idx === 1 ? descontoPorItem + diff : descontoPorItem;

                        return {
                            ...i,
                            vei_desconto_tipo: '$',
                            vei_desconto_valor: descontoItem,
                            vei_valor_total: roundAbnt(
                                (i.vei_quantidade || 0) * (i.vei_valor_unitario || 0) -
                                    descontoItem,
                            ),
                        };
                    });

                    const valorTotal = roundAbnt(ven_produtos_valor - descontoTotal);

                    set((s) => ({
                        venda: s.venda
                            ? {
                                  ...s.venda,
                                  itens: newItens,
                                  ven_desconto_tipo: tipoDesconto,
                                  ven_desconto_valor: desconto,
                                  ven_valor_total: valorTotal,
                              }
                            : undefined,
                    }));
                },

                updateTotalVenda() {
                    const itens = get().venda?.itens || [];

                    let ven_produtos_valor = 0;
                    let ven_desconto_valor = 0;
                    let ven_volume_qtde = 0;
                    let ven_valor_total = 0;
                    let ven_peso_bruto = 0;
                    let ven_peso_liquido = 0;

                    for (const item of itens) {
                        ven_produtos_valor +=
                            (item.vei_quantidade || 1) * (item.vei_valor_unitario || 0);

                        if (item.vei_desconto_tipo === '$') {
                            ven_desconto_valor += item.vei_desconto_valor || 0;
                        } else {
                            const valorSemDesconto =
                                (item.vei_quantidade || 0) * (item.vei_valor_unitario || 0);
                            const desconto = (item.vei_desconto_valor || 0) / 100;
                            ven_desconto_valor += desconto * valorSemDesconto;
                        }

                        ven_volume_qtde += (item.vei_volume_qtde || 0) * (item.vei_quantidade || 0);
                        ven_valor_total += item.vei_valor_total || 0;
                        ven_peso_bruto += item.vei_peso_bruto || 0;
                        ven_peso_liquido += item.vei_peso_liquido || 0;
                    }

                    set((s) => ({
                        venda: s.venda
                            ? {
                                  ...s.venda,
                                  ven_produtos_valor: roundAbnt(ven_produtos_valor, PRECISION_PDV),
                                  ven_desconto_tipo: '$',
                                  ven_desconto_valor: roundAbnt(ven_desconto_valor, PRECISION_PDV),
                                  ven_valor_total: roundAbnt(ven_valor_total),
                                  ven_volume_qtde,
                                  ven_peso_bruto,
                                  ven_peso_liquido,
                              }
                            : undefined,
                    }));
                },

                finalizarVenda(gerouNfce: boolean): void {
                    queryClient.invalidateQueries({ queryKey: ['apiVendaGet'] });

                    get().updatePdvTitle('VENDA FINALIZADA', false);

                    set({
                        showDrawerEmitir: false,
                        showDrawerFormasPagamento: false,
                        vendaFinalizada: true,
                        gerouNfce,
                    });
                },

                async insertItem(item: PdvVendaItem) {
                    let vendaIdpk = get().venda?.ven_idpk || null;

                    if (!vendaIdpk) {
                        vendaIdpk = await get().newVenda();

                        if (!vendaIdpk) {
                            return;
                        }
                    }

                    get().updatePdvTitle('INSERINDO ITEM', true);

                    const oldItens = get().venda?.itens;
                    const itens = [...(oldItens || []), item];

                    set((s) => ({
                        venda: s.venda ? { ...s.venda, itens } : null,
                    }));

                    set((s) => ({
                        title: `VENDA ${s.venda?.ven_numero} - ${item.vei_produto_nome} - ${numberToCurrency(item.vei_valor_total)}`,
                        focarInputProduto: true,
                    }));

                    get().updateTotalVenda();

                    try {
                        const response = await apiVendaItemPost({
                            params: {
                                venda_idpk: vendaIdpk,
                            },
                            body: item,
                        });

                        const { venda } = get();

                        get().updateItemLocal(item.key, {
                            vei_idpk: response[0]?.vei_idpk,
                            vei_venda_idpk: venda?.ven_idpk,
                        });
                    } catch {
                        get().updatePdvTitle('FALHA AO INSERIR ITEM', false);
                        get().deleteItemLocal(item.key);
                        get().updateTotalVenda();
                    }
                },

                updateItemLocal: (key: string, item: Partial<PdvVendaItem>) => {
                    const oldItens = get().venda?.itens || [];

                    const itens = oldItens.map((i) => {
                        if (i.key === key) {
                            const newItem = {
                                ...i,
                                ...item,
                            };

                            newItem.vei_valor_total = calcularTotalProduto(newItem);
                            return newItem;
                        }

                        return i;
                    });

                    set((s) => ({
                        venda: s.venda ? { ...s.venda, itens } : null,
                    }));

                    get().updateTotalVenda();
                },

                async updateItem(key: string, item: Partial<PdvVendaItem>) {
                    const { venda } = get();
                    const oldItens = venda?.itens || [];

                    if (!venda?.ven_idpk || oldItens.length === 0) {
                        notifyError({
                            message: 'Falha ao alterar item',
                            description:
                                'Não há uma venda aberta ou a venda aberta não possui itens',
                        });
                        return;
                    }

                    const oldItem = oldItens.find((i) => i.key === key);
                    if (!oldItem) {
                        notifyError({
                            message: 'Falha ao alterar item',
                            description: 'Item não encontrado',
                        });
                        return;
                    }

                    const newItem: PdvVendaItem = {
                        ...oldItem,
                        ...item,
                    };

                    if (!newItem.vei_idpk) {
                        return;
                    }

                    get().updateItemLocal(key, newItem);

                    try {
                        await apiVendaItemPut({
                            idpk: newItem.vei_idpk,
                            params: {
                                venda_idpk: venda.ven_idpk,
                            },
                            body: newItem,
                        });
                    } catch {
                        get().deleteItemLocal(key);
                    }
                },

                deleteItemLocal(key: string) {
                    set((s) => {
                        if (!s.venda) {
                            return {};
                        }

                        if (!s.venda.itens) {
                            return { venda: { ...s.venda, itens: [] } };
                        }

                        const itens = s.venda.itens.filter((i) => i.key !== key);

                        return {
                            title: `VENDA ${s.venda.ven_numero}`,
                            venda: { ...s.venda, itens: itens },
                        };
                    });

                    get().updateTotalVenda();
                },

                async deleteItem(key: string) {
                    const { venda } = get();
                    const oldItens = venda?.itens || [];

                    if (!venda?.ven_idpk || oldItens.length === 0) {
                        return;
                    }

                    const item = oldItens.find((item) => item.key === key);
                    if (!item?.vei_idpk) {
                        return;
                    }

                    get().deleteItemLocal(key);

                    try {
                        await apiVendaItemDelete({
                            idpk: item.vei_idpk,
                            params: {
                                venda_idpk: venda.ven_idpk,
                            },
                        });
                    } catch {
                        set((s) => ({
                            venda: s.venda ? { ...s.venda, itens: oldItens } : null,
                        }));
                    }
                },

                async updateVendaFormasPagamento(): AsyncResult<null, string> {
                    const venda_idpk = get().venda?.ven_idpk;
                    if (!venda_idpk) {
                        return err('Nenhuma venda aberta');
                    }

                    try {
                        const novasFormas = await apiVendaFormaPagamentoGet({
                            params: { venda_idpk },
                        });

                        const pdvFormas: PdvVendaFormaPagamento[] = novasFormas.map((f) => {
                            return {
                                ...f,
                                key: f.vfp_idpk.toString(),
                            };
                        });

                        set((s) => ({
                            venda: s.venda
                                ? {
                                      ...s.venda,
                                      formas_de_pagamento: pdvFormas,
                                  }
                                : null,
                        }));

                        return ok(null);
                    } catch (e) {
                        return err(e.message);
                    }
                },

                async insertFormaPagamento(
                    valor: number,
                    formaPagamento: ApiFormaPagamento,
                ): AsyncResult<string, string> {
                    const { venda } = get();
                    if (!venda?.ven_idpk) {
                        return err('Nenhuma venda aberta');
                    }

                    const totalVenda = venda.ven_valor_total || 0;
                    const formas = venda.formas_de_pagamento || [];
                    const totalFormas = formas.reduce(
                        (acc, current) => acc + (current.vfp_forma_pagamento_valor || 0),
                        0,
                    );

                    if (totalFormas >= totalVenda) {
                        return err('Soma das formas de pagamento ultrapassa o total da venda');
                    }

                    const podeCartaoPix = await verificarFormaPagamentoCartaoPix(
                        formaPagamento.fop_classificacao,
                    );

                    if (podeCartaoPix.isErr()) {
                        return err(podeCartaoPix.error);
                    }

                    const vendaFormaPagamento = formaPagamentoToVendaFormaPagamento(
                        formaPagamento,
                        venda.ven_idpk,
                        valor,
                    );

                    // Pix e cartão precisa de mais dados para incluir a forma
                    const isCartaoPix = getIsCartaoOrPix(formaPagamento.fop_classificacao);
                    if (isCartaoPix) {
                        set((s) => ({
                            venda: {
                                ...s.venda,
                                formas_de_pagamento: [
                                    ...(s.venda?.formas_de_pagamento || []),
                                    vendaFormaPagamento,
                                ],
                            },
                        }));

                        return ok(vendaFormaPagamento.key);
                    }

                    try {
                        const response = await apiVendaFormaPagamentoPost({
                            body: vendaFormaPagamento,
                            params: { venda_idpk: venda.ven_idpk },
                        });

                        const novaForma = response[0];
                        if (!novaForma) {
                            return err('Forma de pagamento não encontrada');
                        }

                        await get().updateVendaFormasPagamento();
                        return ok(novaForma.vfp_idpk.toString());
                    } catch (e) {
                        return err(e.message);
                    }
                },

                async updateFormaPagamento(
                    key: string,
                    formaPagamento: Partial<PdvVendaFormaPagamento>,
                ): AsyncResult<null, string> {
                    const { venda } = get();
                    if (!venda?.ven_idpk) {
                        return err('Nenhuma venda aberta');
                    }

                    const formasPagamento = [...(get().venda?.formas_de_pagamento || [])];
                    const index = formasPagamento.findIndex((f) => f.key === key);
                    let vendaForma = formasPagamento[index];
                    if (!vendaForma) {
                        return err('Forma de pagamento não encontrada');
                    }

                    // Verifica se a forma de pagamento foi alterada
                    // Cartão e pix precisa verificar se pode usar
                    if (
                        vendaForma.vfp_forma_pagamento_idpk !==
                            formaPagamento.vfp_forma_pagamento_idpk &&
                        formaPagamento.vfp_classificacao
                    ) {
                        const podeCartaoPix = await verificarFormaPagamentoCartaoPix(
                            formaPagamento.vfp_classificacao,
                        );

                        if (podeCartaoPix.isErr()) {
                            set((s) => ({
                                venda: { ...s.venda, formas_de_pagamento: formasPagamento },
                            }));

                            return err(podeCartaoPix.error);
                        }
                    }

                    vendaForma = { ...vendaForma, ...formaPagamento };
                    vendaForma.vfp_forma_pagamento_parcelas = vendaForma.parcelas?.length || 0;
                    formasPagamento[index] = vendaForma;
                    set((s) => ({ venda: { ...s.venda, formas_de_pagamento: formasPagamento } }));

                    if (vendaForma.vfp_idpk) {
                        await apiVendaFormaPagamentoPut({
                            idpk: vendaForma.vfp_idpk,
                            params: { venda_idpk: venda.ven_idpk },
                            body: vendaForma,
                        });
                    }

                    await get().updateVendaFormasPagamento();
                    return ok(null);
                },

                async deleteFormaPagamento(key: string): AsyncResult<null, string> {
                    const venda_idpk = get().venda?.ven_idpk;
                    if (!venda_idpk) {
                        return err('Nenhuma venda aberta');
                    }

                    const formasPagamento = get().venda?.formas_de_pagamento || [];
                    const newFormasPagamento = formasPagamento.filter((f) => f.key !== key);

                    set((s) => ({
                        venda: { ...s.venda, formas_de_pagamento: newFormasPagamento },
                    }));

                    try {
                        const forma = formasPagamento.find((f) => f.key === key);

                        if (!forma?.vfp_idpk) {
                            return ok(null);
                        }

                        await apiVendaFormaPagamentoDelete({
                            idpk: forma?.vfp_idpk || 0,
                            params: { venda_idpk },
                        });

                        await get().updateVendaFormasPagamento();
                        return ok(null);
                    } catch (e) {
                        return err(e.message);
                    }
                },
            }),
            {
                name: 'pdv',
                partialize: (state) => ({
                    favoriteProducts: state.favoriteProducts,
                    fastMode: state.fastMode,
                }),
            },
        ),
        { name: 'pdv' },
    ),
);
