import { notification } from 'antd';
import type { ApiClienteUsuario, ClientePostResponse } from 'features/cliente/types';
import { useLoginStore } from 'features/login/store';
import { userHasPermission } from 'features/login/util';
import { TipoPermissao } from 'features/usuario/types/ApiUsuarioTipoPermissao';
import type { RootDispatch, RootState } from 'state/store';
import { type BuildUrlParams, buildUrl } from 'std/api/buildUrl';
import { comTokenGet, comTokenPost, comTokenPut, comTokenRemove } from 'std/api/comToken';
import type { ApiResponse } from 'std/api/types';
import { throwIfResponseIsErr } from 'std/api/util';
import { endReduxFnError, endReduxFnOk, startReduxFn } from 'std/redux';
import type { DocumentoExiste } from 'std/types';
import { Endpoint } from 'std/types/enum/endpoint';
import { GetTypes, type GetTypesProps } from 'std/types/interfaces/GetTypes';

export const effects = (dispatch: RootDispatch) => ({
    async get(
        payload: {
            type: GetTypesProps;
            customUrl?: string;
            clienteIdpk?: number | string;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.clientes.setState({
            get: startReduxFn(state.clientes.get.data),
        });

        const { type, customUrl, clienteIdpk } = payload;

        // paginação precisa ser zerada antes de criar params
        if (
            type === GetTypes.recordFiltering ||
            type === GetTypes.addRecord ||
            type === GetTypes.singleRecord
        ) {
            dispatch.clientes.setState({
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
            });
        }

        const {
            shouldSearch,
            filterNome,
            pagination,
            sortParams,
            filterCidade,
            filterUf,
            filterRamoAtividade,
            filterMarcador,
        } = state.clientes;

        const params: BuildUrlParams = {
            empresa_idpk: useLoginStore.getState().empresaIdpk,
            total_registros: type === GetTypes.paginationChange ? 'N' : 'S',
            registro_inicial: (pagination.current - 1) * (pagination.pageSize || 0),
            qtde_registros: pagination?.pageSize || 10,
            uf: filterUf,
            cidade_idpk: filterCidade?.cid_idpk,
            ramo_atividade_idpk: filterRamoAtividade?.cra_idpk,
            marcadores: filterMarcador,
            ...(sortParams.shouldSort
                ? { orderby: `${sortParams.fieldName} ${sortParams.orderDirection}` }
                : { orderby: 'pes_nome_razaosocial asc' }),
            ...(shouldSearch && { pesquisar: filterNome }),
            ...(!userHasPermission('upp_cadastro_cliente', TipoPermissao.Parcial) && {
                vendedor_idpk: useLoginStore.getState().login?.usu_idpk,
            }),
        };

        let url: string;
        if (type === GetTypes.customUrl && customUrl) {
            url = customUrl;
        } else if (clienteIdpk) {
            url = buildUrl(Endpoint.Cliente, params, clienteIdpk);
        } else {
            url = buildUrl(Endpoint.Cliente, params);
        }

        try {
            const response: ApiResponse = await comTokenGet(url);
            throwIfResponseIsErr(response);

            dispatch.clientes.setState({
                get: endReduxFnOk(response.data.registros || []),
            });

            // paginação e filtros devem ser zerados após um get que alteraria a qtde de paginas
            if (type === GetTypes.firstGet || type === GetTypes.addRecord) {
                dispatch.clientes.setState({
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                    shouldSearch: false,
                });
            }

            // a paginação que já existe no state deve ser mantida
            // mas o total/showTotal deve mudar
            if (type === GetTypes.recordFiltering || type === GetTypes.deleteRecord) {
                dispatch.clientes.setState({
                    pagination: {
                        ...pagination,
                        total: response.data.total_registros
                            ? Number(response.data.total_registros)
                            : 0,
                        showTotal: () => `Total de Registros: ${response.data.total_registros}`,
                        showSizeChanger: Number(response.data.total_registros) > 10,
                    },
                });
            }
        } catch (error) {
            dispatch.clientes.setState({
                get: endReduxFnError(error),
            });
        }
    },

    async post(
        payload: { body: Record<string, any> },
        state: RootState,
    ): Promise<ClientePostResponse | 'error'> {
        dispatch.clientes.setState({
            post: startReduxFn(state.clientes.post.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Cliente, params);
            const response: ApiResponse = await comTokenPost(url, payload.body);
            throwIfResponseIsErr(response);

            const newCliente =
                response.data.registros && (response.data.registros[0] as ClientePostResponse);

            const cli_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].cli_idpk
                : 0;

            dispatch.clientes.setState({
                post: endReduxFnOk(cli_idpk),
                showModal: false,
            });

            dispatch.clientes.get({
                type: GetTypes.addRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Cliente cadastrado',
            });

            return newCliente || 'error';
        } catch (error) {
            dispatch.clientes.setState({
                post: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível cadastrar o cliente!',
                description: error.message,
            });

            return 'error';
        }
    },

    async put(
        payload: {
            cli_idpk: number;
            body: Record<string, any>;
        },
        state: RootState,
    ): Promise<void> {
        dispatch.clientes.setState({
            put: startReduxFn(state.clientes.put.data),
        });

        try {
            const { cli_idpk, body } = payload;
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Cliente, params, cli_idpk);
            const response: ApiResponse = await comTokenPut(url, body);
            throwIfResponseIsErr(response);

            dispatch.clientes.setState({
                put: endReduxFnOk(null),
                showModal: false,
            });

            dispatch.clientes.get({
                type: GetTypes.editRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Cliente atualizado',
            });
        } catch (error) {
            dispatch.clientes.setState({
                put: endReduxFnError(error),
            });

            notification.error({
                message: 'Não foi possível atualizar o cliente',
                description: error.message,
            });
        }
    },

    async remove(payload: { cli_idpk: number }, state: RootState): Promise<void> {
        dispatch.clientes.setState({
            remove: startReduxFn(state.clientes.remove.data),
        });

        try {
            const params = { empresa_idpk: useLoginStore.getState().empresaIdpk };
            const url = buildUrl(Endpoint.Cliente, params, payload.cli_idpk);
            const response: ApiResponse = await comTokenRemove(url);
            throwIfResponseIsErr(response);

            dispatch.clientes.setState({
                remove: endReduxFnOk(null),
            });

            dispatch.clientes.get({
                type: GetTypes.deleteRecord,
            });

            notification.success({
                message: 'Feito!',
                description: 'Cliente excluído',
            });
        } catch (error) {
            dispatch.clientes.setState({
                remove: endReduxFnError(error),
            });

            notification.error({
                message: 'Falhou!',
                description: String(error),
            });
        }
    },

    async postClienteUser(payload: {
        body: Partial<ApiClienteUsuario>[];
        cli_idpk: number;
    }): Promise<void> {
        dispatch.clientes.setState({
            postClienteUser: startReduxFn(),
        });

        try {
            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                cliente_idpk: payload.cli_idpk,
            };

            const url = buildUrl(Endpoint.ClienteAtualizarUsuarios, params);
            const response: ApiResponse = await comTokenPost(url, payload.body);

            throwIfResponseIsErr(response);

            const clu_cliente_idpk: number = response.data.registros?.[0]
                ? response.data.registros[0].clu_cliente_idpk
                : 0;

            dispatch.clientes.setState({
                postClienteUser: endReduxFnOk(clu_cliente_idpk),
            });
        } catch (error) {
            dispatch.clientes.setState({
                postClienteUser: endReduxFnError(error),
            });
        }
    },

    async documentoExiste(
        payload: { documento: string },
        state: RootState,
    ): Promise<DocumentoExiste | null> {
        dispatch.clientes.setState({
            documentoExiste: startReduxFn(state.clientes.documentoExiste.data),
        });

        const { documento } = payload;

        try {
            const params = {
                empresa_idpk: useLoginStore.getState().empresaIdpk,
                documento,
            };

            const url = buildUrl(Endpoint.ClienteDocumentoExiste, params);
            const response: ApiResponse = await comTokenGet(url);

            // Não podemos usar a função throwIfResponseIsErr aqui
            // pois o back retorna erro caso tiver cliente cadastrado
            if (response.status !== 200) {
                throw new Error();
            }

            let data: DocumentoExiste;

            // Já tem pessoa / cliente cadastrado
            if (response.data.personalizado?.idpk) {
                data = {
                    isPessoa: true,
                    isRegistered: true,
                    idpk: response.data.personalizado?.idpk,
                    mensagem: response.data.mensagem,
                    pessoa: undefined,
                };
            } else if ((response.data.registros || []).length === 0) {
                // Se retornar registros como um array vazio quer dizer que não tem pessoa / cliente cadastrado

                data = {
                    isPessoa: false,
                    isRegistered: false,
                    idpk: undefined,
                    mensagem: undefined,
                    pessoa: undefined,
                };
            } else {
                // Tem pessoa cadastrada mas não tem cliente cadastrado
                data = {
                    isPessoa: true,
                    isRegistered: false,
                    idpk: undefined,
                    mensagem: undefined,
                    pessoa: response.data.registros?.[0],
                };
            }

            dispatch.clientes.setState({
                documentoExiste: endReduxFnOk(data),
            });

            return data;
        } catch (error) {
            dispatch.clientes.setState({
                documentoExiste: endReduxFnError(error),
            });

            return null;
        }
    },
});
