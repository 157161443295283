import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type LayoutStore = {
    breadcrumbs: string[];

    isFullscreen: boolean;

    menuOpen: boolean;
    menuMobile: boolean;
    menuOpenKeys: string[];
    menuSelectedKey: string;

    newVersion?: string;

    toggleFullscreen: () => void;
};

export const useLayoutStore = create<LayoutStore>()(
    devtools(
        persist(
            (_, get) => ({
                breadcrumbs: [],

                isFullscreen: false,

                menuOpen: true,
                menuMobile: false,
                menuOpenKeys: [],
                menuSelectedKey: 'painel-gerencial',

                toggleFullscreen() {
                    const { isFullscreen } = get();

                    if (isFullscreen) {
                        document.exitFullscreen();
                    } else {
                        document.documentElement.requestFullscreen();
                    }
                },
            }),
            {
                name: 'layout',
                partialize: (state) => ({
                    menuOpenKeys: state.menuOpenKeys,
                    menuSelectedKey: state.menuSelectedKey,
                }),
            },
        ),
        { name: 'layout' },
    ),
);
