import { createFileRoute, useMatch, useMatches } from '@tanstack/react-router';
import { Grid, Layout } from 'antd';
import { BannerInstrucoesDeUso } from 'components-old/merc/banners/BannerInstrucoesDeUso';
import { useConfiguracaoStore } from 'features/configuracao/store';
import { HeaderPendente } from 'features/empresa/components/HeaderPendente';
import { ModalEmpresaSituacao } from 'features/empresa/components/ModalEmpresaSituacao';
import { Breadcrumbs } from 'features/layout/components/Breadcrumbs';
import { Footer } from 'features/layout/components/Footer';
import { HeaderVersao } from 'features/layout/components/HeaderVersao';
import { Menu } from 'features/layout/components/Menu/Menu';
import { ModalRenovandoToken } from 'features/layout/components/ModalRenovandoToken';
import { TopBar } from 'features/layout/components/TopBar/TopBar';
import { AnimatePresence } from 'framer-motion';
import { AnimatedOutlet } from 'router/AnimatedOutlet';

export const Route = createFileRoute('/a')({
    component: LayoutPrivado,
});

const { useBreakpoint } = Grid;

function LayoutPrivado() {
    const screens = useBreakpoint();
    const showBanner = useConfiguracaoStore((s) => s.showBannerInstrucoesUso);

    const matches = useMatches();
    const match = useMatch({ strict: false });
    const nextMatchIndex = matches.findIndex((d) => d.id === match.id) + 1;
    const nextMatch = matches[nextMatchIndex];

    const isPdv = window.location.pathname === '/a/pdv';

    return (
        <div className='bg-gray-100'>
            <ModalRenovandoToken />
            <ModalEmpresaSituacao />
            <HeaderVersao />

            <Layout className='bg-gray-100 shadow-md'>
                <Menu />

                <Layout>
                    {isPdv ? (
                        <AnimatePresence mode='wait'>
                            <AnimatedOutlet key={nextMatch?.id} />
                        </AnimatePresence>
                    ) : (
                        <>
                            <Layout.Header className='sticky top-0 z-[998] bg-white'>
                                <HeaderPendente />
                                <TopBar />
                            </Layout.Header>

                            <Breadcrumbs />

                            {showBanner && (
                                <div
                                    className={`w-full  mt-0 mr-auto ${screens.md ? 'pt-2 pl-4 pr-4' : 'pt-2 pl-2 pr-2'}`}
                                >
                                    <BannerInstrucoesDeUso />
                                </div>
                            )}

                            <Layout.Content style={{ height: '100%', position: 'relative' }}>
                                <div
                                    className={`w-full  mt-0 mr-auto  ${screens.md ? 'pt-2 pl-4 pr-4' : 'pt-2 pl-2 pr-2'}`}
                                >
                                    <AnimatePresence mode='wait'>
                                        <AnimatedOutlet key={nextMatch?.id} />
                                    </AnimatePresence>
                                </div>
                            </Layout.Content>

                            <Layout.Footer>
                                <Footer />
                            </Layout.Footer>
                        </>
                    )}
                </Layout>
            </Layout>
        </div>
    );
}
